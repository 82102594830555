import { motion } from "framer-motion";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
function Accordion({accordionData}) {
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });
    const [openSection, setOpenSection] = useState(accordionData.seguros.length> 0 ? "seguros" : "creditos");
    const [openSubItem, setOpenSubItem] = useState(null);


    if (accordionData.seguros.length ===0 && accordionData.creditos.length ===0){
        return null;
    }
    console.log(accordionData);
   /* const accordionData = {
        "seguros": [
            { id: "1", title: "Pessoal e Familiar", icon: "p4l-family", description: "Tem 5 Activos", premium: "5000€", color: "text-green-400" },
            { id: "2", title: "Saúde", icon: "p4l-health", description: "Tem 5 Activos", premium: "5000€", color: "text-pink-400" },
            { id: "3", title: "Não Vida", icon: "p4l-lifebuoy", description: "Tem 3 Activos", premium: "5000€", color: "text-orange-400" }
        ],
        "creditos": [
            { id: "4", title: "Crédito Pessoal", icon: "p4l-golden-bars", description: "Tem 3 Activos", premium: "3000€", color: "text-blue-400" },
            { id: "5", title: "Crédito Habitação", icon: "p4l-home", description: "Tem 2 Activos", premium: "5000€", color: "text-green-400" },
            { id: "6", title: "Crédito Consolidado", icon: "p4l-golden-bars", description: "Tem 4 Activos", premium: "7000€", color: "text-orange-400" }
        ]
    };*/
    const toggleSection = (section) => setOpenSection(section);
    const toggleSubItem = (subItem) => setOpenSubItem(openSubItem === subItem ? null : subItem);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.1, delayChildren: 0.2 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { type: "spring", stiffness: 100 } },
    };
    const subItemVariants = {
        closed: { opacity: 0, height: 0, transition: { duration: 0.2 } },
        open: { opacity: 1, height: "auto", transition: { duration: 0.2 } }
    };
    return (
        <React.Fragment>
            <div className="w-full">
                <div className="flex justify-start sm:justify-center bg-gray-100 text-2xl overflow-x-auto whitespace-nowrap w-full">
                {Object.keys(accordionData).map((sectionKey) =>
    accordionData[sectionKey].length > 0 && (
        <button
            key={sectionKey}
            onClick={() => toggleSection(sectionKey)}
            className={`border tab-button inline-block min-w-64 py-9 px-2 transition-all duration-200 ease-out ${
                openSection === sectionKey
                    ? 'text-black bg-white'
                    : 'text-gray-400 hover:bg-white hover:text-black'
            } font-bold`}
        >
            {sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)}
        </button>
    )
)}
                </div>
            </div>
            <div ref={ref} className="w-full p-6 bg-white">
                {openSection && (
                    <div id={openSection} className="tab-content w-full">
                        <motion.div initial="hidden" animate={inView ? "visible" : "hidden"} variants={containerVariants} className="flex flex-col gap-6 w-full">

                            {accordionData[openSection].length > 0 && accordionData[openSection].map((item) => (
                                <motion.div key={item.id} variants={itemVariants} className="flex flex-col items-center gap-2 w-full">
                                    <div onClick={() => toggleSubItem(item.id)}
                                         className={`flex items-center  justify-between w-full sm:w-2/3 xl:w-1/2 px-6 py-2 sm:py-4 gap-3 font-medium text-gray-500 border border-b-0 border-gray-200 rounded-full cursor-pointer ${item.color}`} style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }}>
                                        <div className="flex justify-between items-center w-full">
                                            <div className="flex gap-4 items-center">
                                                <span className={`${item.icon} text-4xl text-${item.color}-400`}></span>
                                                <span>{item.title}</span>
                                            </div>
                                            <p className={`text-${item.color}-400`}>{item.description}</p>
                                        </div>
                                        <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
                                        </svg>
                                    </div>
                                    {openSubItem === item.id && (
                                         <motion.div
                                         variants={subItemVariants}
                                         initial="closed"
                                         animate={openSubItem === item.id ? "open" : "closed"} className={`flex  border p-6 border-gray-200 flex-wrap w-full rounded-lg sm:w-2/3 xl:w-1/2`} aria-labelledby={`accordion-collapse-heading-${item.id}`}>
                                            <Link to={item.link}>
                                            <div style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex rounded-lg md:flex-row md:max-w-xl">
                                                <div className="p-6 rounded-t-lg md:rounded-none md:rounded-s-lg flex items-center justify-center">
                                                    <span className={`${item.icon} text-4xl`}></span>
                                                </div>
                                                <div className="flex flex-col items-center gap-4 w-full p-4 leading-normal">
                                                    <p className="text-center">Instituição financeira</p>
                                                    <div className="flex w-full justify-around">
                                                        <div className="flex flex-col gap-2 items-center px-8">
                                                            <p>Prémio</p>
                                                            <p className="font-extrabold">{item.premium}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </Link>
                                        </motion.div>
                                    )}
                                </motion.div>
                            )) }
                        </motion.div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default Accordion;
