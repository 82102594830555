import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Card, CardHeader, CardContent } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin

// Register necessary chart elements and the plugin
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const HalfDonutChart = ({ chartData, legendColor = "text.secondary" }) => {
  // Extract labels and data from chartData
  const { labels, data } = chartData;

  // Calculate total sum for percentage calculation
  const total = data.reduce((sum, value) => sum + value, 0);

  // Generate the dataset dynamically from the passed chartData
  const chartDataFormatted = {
    labels: labels, // The category labels (e.g., "Contas", "Poupança", etc.)
    datasets: [
      {
        label: "Data values",
        data: data, // Corresponding values for each label
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(54, 162, 235, 0.6)",
        ], // Background colors for the chart segments
        borderColor: "transparent", // Border color set to transparent
        borderWidth: 1,
      },
    ],
  };

  // Custom chart options to make it a half-circle donut
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 500 },

    layout: {
      padding: {
        top: -5,
        bottom: -45,
      },
    },
    cutout: "60%", // Defines the donut hole size
    circumference: Math.PI, // 180 degrees for half donut
    rotation: -Math.PI / 2, // Start from the top (12 o'clock position)
    plugins: {
      legend: {
        display: true, // Ensure the legend is displayed
        position: "right", // Position the legend to the right
        labels: {
          padding: 25,
          boxWidth: 9,
          color: legendColor, // Dynamically use the passed color for the legend text
          usePointStyle: true,
        },
      },
      datalabels: {
        display: true,
        color: "#fff", // White color for text inside the chart
        formatter: (value) => {
          const percentage = ((value / total) * 100).toFixed(1); // Calculate the percentage
          return `${percentage}%`; // Display the percentage inside the arc
        },
        font: {
          weight: "bold",
          size: 12, // Adjust the size of the labels
        },
        align: "center", // Align the text at the center of each arc
        anchor: "center", // Anchor the text to the center of each segment
      },
    },
  };

  return (
    <Card
      className="w-full md:w-1/2 lg:w-1/3"
      style={{
        WebkitBoxShadow: "0px 0px 12px 0px #bdcdd1", // Adding subtle shadow effect
        maxWidth: "100%",
      }}
    >
      <CardHeader
        title="Half Donut Chart"
        subheader="Chart.js Half Donut Chart Example"
        sx={{
          flexDirection: ["column", "row"],
          alignItems: ["flex-start", "center"],
          "& .MuiCardHeader-action": { mb: 0 },
          "& .MuiCardHeader-content": { mb: [2, 0] },
        }}
      />
      <CardContent>
        {/* Render Half Donut chart */}
        <Doughnut data={chartDataFormatted} height={350} options={options} />
      </CardContent>
    </Card>
  );
};

export default HalfDonutChart;
