import React, { useState, useEffect, useMemo } from 'react';
import { useLoaderData, Link } from 'react-router-dom';
import Accordion from '../components/UI/Accordion';
import Chat from '../components/FloatingChat/Chat';
import ContentPage from './ContentPage';
import Swiper from '../components/Swiper/Swiper';
import { fetchFinancialDetails,profileDetail } from '../utils/cms';
import { format } from 'date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchProfileCompleteness, fetchSwiperImages } from '../utils/cms';
import AlertsContainer from '../components/UI/AlertMessage/AlertsContainer';
import ProfileProgressBar from '../components/ProfileProgressBar/ProfileProgressBar';
import Calendar from '../components/Calendar/Calendar';
import DonutChart from '../components/Widgets/DonutChart';
import StackBarChartApex from '../components/Widgets/StackBarChartApex';
import RadialChartApex from '../components/Widgets/RadialChartApex';
import PolarAreaChart from '../components/Widgets/PolarAreaChart';
import HalfDonutChart from '../components/Widgets/HalfDonutChart';
import CardMessage from '../components/UI/CardMessage';
import CardMessageButton from '../components/UI/CardMessageButton';
import { contentLoaderAlternative } from './ContentPage';
import { Accordion as AccordionMUI , AccordionDetails, AccordionSummary,Typography, CircularProgress } from '@mui/material';
function Home() {
  const loaderData = useLoaderData();
const { profileCompletenessItems , financialStatus , profileDetails,contentDataLoader,swiperImages} = loaderData;

const [numberEvents, setNumberEvents] = useState(null); // Loading state

  const issuesCount = profileCompletenessItems?.steps?.length || 0;

  return (
    <React.Fragment>


      <div className='flex flex-col gap-8 '>
        <div className='flex flex-col '>
        {profileCompletenessItems?.percentage <100 && <ProfileProgressBar progress={profileCompletenessItems.percentage} />}

        {/* Alerts Container */}
        {profileCompletenessItems?.steps?.length>0 && <AlertsContainer issuesCount={issuesCount} steps={profileCompletenessItems.steps || []} />}



        </div>

      <AccordionMUI sx={{ bgcolor: "white"}}>
          {numberEvents ===null ? <CircularProgress/> : <AccordionSummary    expandIcon={<ExpandMoreIcon />}>
          <Typography className='flex gap-4 items-center' >
          <span className='p4l-calendar  text-blue-400  text-5xl'></span>
          {numberEvents > 0 ?
            <>

              {`Tem ${numberEvents} ${numberEvents ===1 ? "evento":"eventos"} para esta semana`} {/* Use translation with dynamic count */}
            </> : 'Não tem eventos esta semana'
          }
        </Typography>

          </AccordionSummary>}
          <AccordionDetails>
          <Calendar
          initialView="listWeek" // Define the initial view
          views={['listWeek']} // Define the available views
          updateNumberEvents={setNumberEvents}
        />
          </AccordionDetails>
          </AccordionMUI>
<div className='flex  justify-center items-center gap-6'>
  {profileCompletenessItems?.financial_health === false &&<CardMessageButton title="Saude Financeira" text="Ainda não calculou a saúde financeira" buttonText="Calcular" buttonLink="/pages/financial-health-summary" icon="p4l-lock text-pink-400 " /> }
  {profileCompletenessItems?.financial_health === true && profileCompletenessItems?.financial_health_data && <CardMessageButton title="Saude Financeira" text={`Saúde Financeira ${profileCompletenessItems.financial_health_data.estado}`} buttonText="Ver mais" buttonLink="/pages/financial-health-summary" emoji={profileCompletenessItems.financial_health_data.emoji} /> }
{financialStatus && financialStatus.official_status.at_status ==='GREEN' && <CardMessageButton title="Portal das finanças" text="Situação fiscal regularizada" subText={`${format(new Date(financialStatus.official_status.last_updated), 'yyyy-MM-dd HH:mm:ss')} `} buttonText="Ver mais" buttonLink="/profile/financials" icon="p4l-check text-green-400" /> }
{financialStatus && financialStatus.official_status.at_status ==='RED' && <CardMessageButton title="Portal das finanças" text="A sua situação fiscal não está regularizada. Consulte o portal das finanças." buttonText="Portal das finanças" buttonLink="https://www.portaldasfinancas.gov.pt/" icon="p4l-cross text-red-400" /> }
{financialStatus && financialStatus.credentials_status.status ==='NOT_INIT' && <CardMessageButton title="Portal das finanças" text="Ainda não configurou os dados do portal das finanças. "  buttonText="Preencher dados" buttonLink="/profile/financials" icon="p4l-lock text-pink-400" /> }

</div>

        <div className="flex flex-col items-center gap-6">
        { profileCompletenessItems?.accordionData && <Accordion accordionData={profileCompletenessItems.accordionData} />}








</div>
<Chat />
{swiperImages.length>0 && <Swiper images={swiperImages} />}



<div className='flex flex-wrap justify-center gap-6 w-full'>
       {/*profileCompletenessItems?.charts?.credits && <StackBarChartApex chartData={profileCompletenessItems.charts.credits} />*/}
       {/*profileCompletenessItems?.charts?.insurances &&<StackBarChartApex chartData={profileCompletenessItems.charts.insurances} /> */}
       {/*profileCompletenessItems?.charts?.financial_protection &&  <RadialChartApex chartData={profileCompletenessItems.charts.financial_protection} />*/ }
        {/*profileCompletenessItems?.charts?.financial_expenses &&  <PolarAreaChart chartData={profileCompletenessItems.charts.financial_expenses} /> */}
        {profileCompletenessItems?.charts?.financial_income &&  <DonutChart chartData={profileCompletenessItems.charts.financial_income} /> }

        </div>

        {/* Pass the fetched images to the Swiper component */}

        <ContentPage dataloader={contentDataLoader} />


      </div>
    </React.Fragment>
  );
}

export default Home;


export async function loader() {
  let financialDetailsData = null;
  let profileDetailsData = null;
  let profileCompletenessData = null;
  let contentLoaderData = null;
  let swiperImagesData=[];

  try {
    financialDetailsData = await fetchFinancialDetails(); // Fetch financial details from API
  } catch (error) {
    console.error("Error fetching financial details:", error);
    // Optionally set fallback data here
  }

  try {
    profileDetailsData = await profileDetail(); // Fetch profile details from API
  } catch (error) {
    console.error("Error fetching profile details:", error);
    // Optionally set fallback data here
  }

  try {
    profileCompletenessData = await fetchProfileCompleteness(); // Fetch profile completeness
  } catch (error) {
    console.error("Error fetching profile completeness:", error);
    // Optionally set fallback data here
  }

  try {
   contentLoaderData = await contentLoaderAlternative(); // Fetch profile completeness
  } catch (error) {
    console.error("Error fetching content page", error);
    // Optionally set fallback data here
  }
try {
    swiperImagesData = await fetchSwiperImages();

    }
    catch (error) {
    console.error("Error fetching content page", error);
    // Optionally set fallback data here
  }

  const data = {
    financialStatus: financialDetailsData,
    profileDetails: profileDetailsData,
    profileCompletenessItems: profileCompletenessData,
    contentDataLoader : contentLoaderData,
    swiperImages : swiperImagesData.images,
  };

  return data;
}
