import { Outlet } from 'react-router-dom';
import Nav from '../components/Nav/Nav';
import Box from '@mui/material/Box';

import Footer from '../components/Footer/Footer';
import MainSection from '../components/UI/MainSection';
import FloatingMenu from '../components/Nav/FloatingMenu/FloatingMenu';
import React, { useState, useEffect, useMemo, useRef, useCallback, useContext } from 'react';
import AuthContext from '../store/context/auth-context__';
import { useLoaderData, Link,useLocation } from 'react-router-dom';
import { useNavigate, useNavigation } from 'react-router-dom';
import { fetchMenuItems } from '../utils/cms';
import { CircularProgress} from '@mui/material';
import { profileDetail } from '../utils/cms';

import ScrollToTop from '../components/ScrollToTop';
import P4lIcon from '../components/UI/P4lIcon';

function Root() {
  const drawerWidth = 240;
  const [open, setOpen] = useState(false);
  const navigation = useNavigation();
  const navigate = useNavigate();
  const footerRef = useRef(null);
  const loaderData = useLoaderData();
  const [isLoading, setIsLoading] = useState(true);
const {sideBarItems , topNavItems , footerItems,floatingMenuItems} = loaderData;
const location = useLocation();
const isHomePage = location.pathname === '/';
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    authCtx.refetch();
    if (!authCtx.isLoggedIn) {
      navigate("/login");
    }
  }, [authCtx.isLoggedIn, navigate]);
  useEffect(() => {
    if (loaderData) {
      setIsLoading(false);
    }
  }, [loaderData]);

  const handleDrawerOpen = useCallback(() => setOpen(true), []);
  const handleDrawerClose = useCallback(() => setOpen(false), []);



  // Calculate the number of issues
console.log(navigation.state);

  return (
    authCtx.isLoggedIn &&
    <React.Fragment>
      <ScrollToTop>
        <Box className="w-full" sx={{ height: '100%', display: 'flex' }}>

          <Nav
            topNavItems={topNavItems}
            sideBarItems={sideBarItems}
            drawerWidth={drawerWidth}
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
          <Box sx={{ flexGrow: 1, minWidth: 0, display: 'flex', flexDirection: 'column', minHeight: '100vh' }} className="w-full bg-primary-300">
            <main sx={{position:"relative" , flexGrow: 1, width: '100%', transition: 'padding .25s ease-in-out' }}>
            {!isHomePage && navigation.state !== 'loading' &&  (
                    <div className="w-full sm:hidden bg-primary-white min-h-10 sticky top-20 z-50 ">
                      <P4lIcon
                        icon="p4l-arrow-left2 p-2 text-2xl cursor-pointer"
                        size="small"
                        onClick={() => navigate(-1)} // Go back to the previous page
                      />
                    </div>
                  )}
              <MainSection>

                <FloatingMenu footerRef={footerRef} data={floatingMenuItems} />

                {isLoading || navigation.state === 'loading' ? (
 <Box
 className="flex justify-center items-center h-screen"
 sx={{
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   minHeight: '100vh',
   width: '100%',
 }}
>
 <CircularProgress />
</Box>
) : (

  <Outlet />
)}
              </MainSection>

              <div id="button-sticky" className=" sticky bottom-16"></div>

            </main>
            <Footer ref={footerRef} footerItems={footerItems} />
          </Box>

        </Box>
      </ScrollToTop>
    </React.Fragment>
  );
}

export default Root;


export async function loader() {
  let sidebarData = null;
  let footerData = null;
  let topNavData = null;
  let floatingMenuData = null;
  let profileDetailsData = null;

  try {
    sidebarData = await fetchMenuItems('sidebar-menu')
  } catch (error) {
    console.error("Error fetching sidebar menu:", error);
    // Optionally set fallback data here
  }

  try {
    footerData = await fetchMenuItems('footer-menu');
  } catch (error) {
    console.error("Error fetching footer menu:", error);
    // Optionally set fallback data here
  }

  try {
    topNavData = await fetchMenuItems('top-menu');
    profileDetailsData = await profileDetail();

  } catch (error) {
    console.error("Error fetching top menu:", error);
    // Optionally set fallback data here
  }

  try {
    floatingMenuData = await fetchMenuItems('floating-menu');

  } catch (error) {
    console.error("Error fetching floating menu", error);
    // Optionally set fallback data here
  }


  const data = {
    sideBarItems: sidebarData,
    topNavItems: {...topNavData,profileDetails:{...profileDetailsData}},
    footerItems: footerData,
    floatingMenuItems : floatingMenuData,

  };

  return data;
}
